<template>
  <div class="home ">
    <div class="max-content">
      <home-bg></home-bg>
      <home-popular></home-popular>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {
    //
  },
  mounted() {
    this.$nextTick(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    });
  },
  methods: {},
  destroyed() {}
};
</script>
<style lang="scss" scoped></style>
